import validate from "/GitHub/MLB_NUXT/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/GitHub/MLB_NUXT/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-admin": () => import("/GitHub/MLB_NUXT/middleware/is-admin.js"),
  "is-auth": () => import("/GitHub/MLB_NUXT/middleware/is-auth.js"),
  "is-moderator": () => import("/GitHub/MLB_NUXT/middleware/is-moderator.js"),
  "sidebase-auth": () => import("/GitHub/MLB_NUXT/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}