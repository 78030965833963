<template>
  <div>
    <!-- <button
          class="fixed bottom-5 right-5 w-12 h-12 rounded-full bg-red-main  hover:bg-button-secondary text-main-text text-2xl cursor-pointer shadow-lg absolute"
          @click="toggleChat"
        >
          💬
      </button> -->

    <div
      class="fixed bottom-5 rounded-full right-5 w-12 h-12 text-2xl cursor-pointer shadow-lg absolute"
      @click="toggleChat"
    >
      <button
        class="fixed w-12 h-12 rounded-full bg-red-main  hover:bg-button-secondary text-main-text text-2xl cursor-pointer shadow-lg relative"
      >
        💬
        <span
          class="absolute -top-2 -right-2 bg-red-500 text-main-text text-xs font-bold w-6 h-6 flex items-center justify-center rounded-full"
          v-if="totalNotificationsCount > 0"
        >
          +{{ totalNotificationsCount }}
        </span>
      </button>
    </div>

    <div
      v-if="isOpen"
      class="fixed bottom-20 right-5 w-[500px] h-[600px] flex flex-col border border-main-gray-light rounded-lg bg-white shadow-lg font-sans overflow-hidden"
    >
      <!-- Заголовок чата -->
      <div
        class="bg-red-main  hover:bg-button-secondary text-main-text px-4 py-3 flex justify-between items-center text-sm font-semibold rounded-t-lg shadow-md"
      >
        <span v-if="activeChat">
          Chatting with {{ activeChat.friend.FirstName }}
          {{ activeChat.friend.LastName }}
        </span>
        <span v-else>Chat</span>
        <button
          @click="toggleChat"
          class="bg-transparent border-none text-main-text text-xl cursor-pointer hover:text-main-text-gray-light"
        >
          X
        </button>
      </div>

      <div class="flex h-full">
        <!-- Список друзей -->
        <div
          class="w-48 border-r border-main-gray-light px-4 py-2 bg-main-gray-light overflow-y-auto"
        >
          <div class="mb-3">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search..."
              @input="filterFriends"
              class="w-full px-3 py-2 border border-main-gray-light rounded-md text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <!-- System Notifications Button (placé correctement) -->
          <div v-if="customListInvitations.length > 0" class="mb-3">
            <button
              @click="openSystemNotifications"
              class="w-full bg-yellow-400 text-main-text-gray-dark py-2 px-4 rounded-md shadow hover:bg-yellow-500 transition-all duration-200"
            >
              📢 System Notifications ({{ customListInvitations.length }})
            </button>
          </div>

          <div class="chat-friends">
            <ul class="list-none p-0 m-0 max-h-52 overflow-y-auto">
              <li
                v-for="friend in filteredFriends"
                :key="friend.id"
                @click="selectFriend(friend)"
                class="py-2 px-3 border-b border-main-gray-light cursor-pointer rounded-md transition-all duration-200 hover:bg-red-main  hover:bg-button-secondary hover:text-main-text flex items-center justify-between space-x-2 flex-row"
              >
                {{ friend.FirstName }} {{ friend.LastName }}

                <!-- Notification counter -->
                <span
                  class="bg-red-500 text-main-text text-xs font-semibold w-5 h-5 flex items-center justify-center rounded-full ml-2"
                  v-if="unreadMessages[friend.FriendId] > 0"
                >
                  +{{ unreadMessages[friend.FriendId] }}
                </span>
              </li>
            </ul>
          </div>

          <button
            @click="openAddFriendModal"
            class="bg-main-dark hover:bg-main-gray-dark text-main-text py-2 px-4 border-none rounded-md cursor-pointer w-full mt-3 transition-all duration-200"
          >
            + Add Friend
          </button>

          <!-- Список запросов в друзья -->
          <div class="mt-6" v-if="friendRequests.length > 0">
            <h4 class="text-sm font-semibold mb-2">Friend Requests</h4>
            <ul class="list-none p-0 m-0 max-h-40 overflow-y-auto">
              <li
                v-for="request in friendRequests"
                :key="request.Id"
                class="py-2 px-3 border-b border-main-gray-light rounded-md transition-all duration-200"
              >
                <div class="flex justify-between items-center">
                  <span class="text-sm font-semibold">
                    {{ request.FirstName }} {{ request.LastName }}
                  </span>
                  <!-- svg clock pending -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="mt-1 flex justify-end space-x-2">
                  <button
                    @click="acceptRequest(request)"
                    class="text-main-text-dark hover:text-main-text-dark text-sm"
                  >
                    Accept
                  </button>
                  <button
                    @click="rejectRequest(request)"
                    class="text-red-600 hover:text-red-700 text-sm"
                  >
                    Reject
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="activeChat"
          class="flex-1 flex flex-col px-4 py-2 h-full relative"
        >
          <!-- Контейнер для сообщений с прокруткой -->
          <div
            ref="messageContainer"
            class="flex flex-col overflow-y-auto mb-3 max-h-[440px] space-y-2"
          >
            <div
              v-for="(message, index) in activeChat.messages"
              :key="index"
              :class="[
                'inline-block px-4 py-3 rounded-lg text-sm',
                {
                  'self-end bg-red-main  hover:bg-button-secondary text-main-text text-right': message.isSent,
                  'bg-main-gray-light text-main-text-gray-dark w-fit-content': !message.isSent,
                },
              ]"
            >
              {{ message.text }}
              <!-- ✅ Si c'est une invitation, on affiche les boutons -->
              <div
                v-if="message.invitation"
                class="mt-2 flex justify-end space-x-2"
              >
                <button
                  @click="acceptInvitation(message.invitation)"
                  class="px-3 py-1 bg-main text-main-text rounded hover:bg-main-dark"
                >
                  Accept
                </button>
                <button
                  @click="declineInvitation(message.invitation)"
                  class="px-3 py-1 bg-red-500 text-main-text rounded hover:bg-red-600"
                >
                  Decline
                </button>
              </div>
            </div>
          </div>

          <!-- Conteneur du champ de message -->
          <div
            class="flex flex-row mt-4 items-center absolute bottom-4 right-0 w-full px-4"
          >
            <!-- Zone de texte -->
            <textarea
              v-model="newMessage"
              placeholder="Type a message..."
              @keydown.enter.prevent="sendMessage"
              class="flex-grow px-4 py-2 border border-main-gray-light rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden bg-main placeholder-main-gray-light hover:bg-main-gray-dark transition-all text-white duration-150"
            ></textarea>

            <!-- Bouton Emoji -->
            <div class="relative ml-2">
              <button
                @click="toggleEmojiPicker"
                class="p-2 rounded-full bg-main-gray-light hover:bg-main-gray-light focus:outline-none transition-all"
              >
                😀
              </button>

              <!-- Picker Emoji (s'affiche uniquement si ouvert) -->
              <div
                v-if="showEmojiPicker"
                class="absolute bottom-full right-0 mb-2 z-10 bg-white shadow-lg rounded-lg p-2"
              >
                <emoji-picker @select="addEmoji" />
              </div>
            </div>

            <!-- Bouton d'envoi -->
            <button
              @click="sendMessage"
              class="ml-3 bg-red-main  hover:bg-button-secondary text-main-text py-2 px-6 rounded-full hover:bg-button-secondary    focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md flex justify-center items-center transition-all duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isAddFriendModalOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div
        class="bg-white w-80 max-w-full shadow-lg rounded-lg relative overflow-hidden"
      >
        <div
          class="flex justify-between items-center bg-red-main px-6 py-4 text-main-text rounded-t-lg shadow-md"
        >
          <h3 class="text-lg font-semibold">Search and Add Friend</h3>
          <button
            @click="closeAddFriendModal"
            class="bg-transparent border-none text-main-text text-2xl hover:text-main-text-gray-light transition duration-200 cursor-pointer"
          >
            ×
          </button>
        </div>
        <div class="p-6 bg-[#d6d6dc]">
          <input
            type="text"
            v-model="newFriendQuery"
            placeholder="Search for a friend..."
            @input="searchFriends"
            class="w-full px-4 py-3 border border-main-gray-light rounded-lg mb-4 text-sm placeholder-main-gray-light focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
          <ul class="list-none p-0 m-0">
            <li
              v-for="friend in searchResults"
              :key="friend.id"
              class="py-3 flex bg-white justify-between items-center cursor-pointer border-b border-main-gray-light rounded-md transition duration-300 ease-in-out"
            >
              <span
                class="text-lg font-semibold text-main-text-gray-dark w-[60%] overflow-hidden"
                >{{ friend.username }}</span
              >
              <button
                @click="addFriend(friend)"
                class="bg-main-dark hover:bg-red-main  text-main-text px-4 py-2 rounded-lg text-sm font-semibold transition duration-200 focus:outline-none focus:ring-2 focus:ring-main"
              >
                Add Friend
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from "vue";
import axios from "axios";
import CryptoJS from "crypto-js";
import emojiData from "emoji-datasource";
import { Socket } from "socket.io-client";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

const config = useRuntimeConfig();
const API_URL = config.public.apiUrl;

const { $socket } = useNuxtApp(); // Доступ к сокетам

const isOpen = ref(false);
const searchQuery = ref("");
const friends = reactive([]);
const filteredFriends = ref([]);
const customListInvitations = ref([]);
const activeChat = ref(null);
const newMessage = ref("");
const isAddFriendModalOpen = ref(false);
const newFriendQuery = ref("");
const searchResults = ref([]);
const messageContainer = ref(null);
const friendRequests = ref([]);
const userId = ref(null);
const unreadMessages = ref([]);
const totalNotificationsCount = ref(0);
const cryptoKey = config.public.msgEncryptKey;

const showEmojiPicker = ref(false);

const toggleEmojiPicker = () => {
  showEmojiPicker.value = !showEmojiPicker.value;
};

const addEmoji = (emoji) => {
  console.log("Emoji selected:", emoji);
  newMessage.value += emoji.i;
  showEmojiPicker.value = false; // Ferme le picker après sélection
};

onMounted(async () => {
  try {
    userId.value = useAuth().data.value.user.id;

    $socket.emit("joinRoom", { userId: userId.value });

    $socket.on("receiveMessage", (message) => {
      if (message.receiverId === userId.value) {
        if (
          activeChat.value &&
          activeChat.value.friend.FriendId === message.senderId
        ) {
          activeChat.value.messages.push({
            text: decrypt(message.text),
            isSent: false,
          });
          scrollToBottom();
        } else {
          unreadMessages.value[message.senderId] =
            (unreadMessages.value[message.senderId] || 0) + 1;
        }
        totalNotifications();
      }
    });

    $socket.on("Notification", (data) => {
      unreadMessages.value[data.senderId] =
        (unreadMessages.value[data.senderId] || 0) + 1;
      totalNotifications();
    });

    $socket.on("FriendRequest", async (data) => {
      const fetchedRequests = await getFriendRequests();
      friendRequests.value = fetchedRequests;
      totalNotifications();
    });

    $socket.on("AcceptFriend", async (data) => {
      const fetchedFriends = await getFriends();
      friends.splice(0, friends.length, ...fetchedFriends);
      filteredFriends.value = friends;

      const fetchedRequests = await getFriendRequests();
      friendRequests.value = fetchedRequests;
      totalNotifications();
    });

    const fetchedRequests = await getFriendRequests();
    friendRequests.value = fetchedRequests;

    getCustomListsInvitations();

    const fetchedFriends = await getFriends();
    friends.push(...fetchedFriends);
    filteredFriends.value = friends;
  } catch (error) {
    //
  }
});

// Conversion des données emoji
const emojiMap = emojiData.reduce((map, emoji) => {
  map[
    String.fromCodePoint(
      ...emoji.unified.split("-").map((hex) => parseInt(hex, 16))
    )
  ] = `:${emoji.short_name}:`;
  return map;
}, {});

const reverseEmojiMap = Object.fromEntries(
  Object.entries(emojiMap).map(([emoji, shortName]) => [shortName, emoji])
);

// Emoji vers texte
function emojiToText(message) {
  return message.replace(/./gu, (char) => emojiMap[char] || char);
}

// Texte vers emoji
function textToEmoji(message) {
  return message.replace(/:\w+:/g, (match) => reverseEmojiMap[match] || match);
}

// Encrypter
function encrypt(text) {
  const textWithEmoji = emojiToText(text);
  return CryptoJS.AES.encrypt(textWithEmoji, cryptoKey).toString();
}

// Décrypter
function decrypt(encryptedText) {
  const bytes = CryptoJS.AES.decrypt(encryptedText, cryptoKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return textToEmoji(decryptedText);
}

onUnmounted(() => {
  $socket.off("receiveMessage");
});

function openSystemNotifications() {
  // Si une seule invitation, on crée directement un message interactif
  if (customListInvitations.value.length === 1) {
    const invite = customListInvitations.value[0];
    activeChat.value = {
      friend: {
        FirstName: "System",
        LastName: "Notification",
        FriendId: "system-notification",
      },
      messages: [
        {
          text: `You have been invited by ${invite.username} to copy their ${invite.customListType} custom list named "${invite.customlistName}".`,
          isSent: false,
          invitation: invite, // 🔑 On stocke l'invitation complète pour la gérer après
        },
      ],
    };
  }
  // Si plusieurs invitations, on crée une liste de messages
  else {
    console.log("customListInvitations:", customListInvitations.value);

    const messages = customListInvitations.value.map((invite, index) => ({
      text: `📢 [${index + 1}] Invitation to copy the list "${
        invite.customlistName
      }" (type: ${invite.customListType}).`,
      isSent: false,
      invitation: invite, // 🔑 On stocke ici aussi pour gérer les boutons
    }));

    activeChat.value = {
      friend: {
        FirstName: "System",
        LastName: "Notification",
        FriendId: "system-notification",
      },
      messages: messages,
    };
  }
}

const toggleChat = () => {
  isOpen.value = !isOpen.value;
};

const filterFriends = () => {
  const query = searchQuery.value.toLowerCase();
  filteredFriends.value = friends.filter((friend) =>
    friend.name.toLowerCase().includes(query)
  );
};

const totalNotifications = () => {
  totalNotificationsCount.value =
    friendRequests.value.length +
    Object.values(unreadMessages.value).reduce((acc, count) => acc + count, 0);
};

async function selectFriend(friend) {
  try {
    activeChat.value = {
      friend,
      messages: [],
    };

    const response = await axios.get(
      API_URL + `get-messages/${userId.value}/${friend.FriendId}`
    );

    activeChat.value.messages = response.data.Messages.map((msg) => ({
      text: decrypt(msg.msg),
      isSent: String(msg.msgFrom) === String(userId.value),
    }));

    unreadMessages.value[friend.FriendId] = 0;
    totalNotifications();

    const roomId = createRoomId(userId.value, friend.FriendId);
    $socket.emit("joinRoom", { roomId });

    scrollToBottom();
  } catch (error) {
    console.error("Error in selectFriend:", error);
  }
}

function createRoomId(userId1, userId2) {
  return `room-${Math.min(userId1, userId2)}-${Math.max(userId1, userId2)}`;
}

async function sendMessage() {
  try {
    if (newMessage.value.trim() && activeChat.value) {
      const message = {
        text: newMessage.value,
        isSent: true,
        roomId: `room-${activeChat.value.friend.Id}`,
      };

      const roomId = createRoomId(
        userId.value,
        activeChat.value.friend.FriendId
      );

      $socket.emit("sendMessage", {
        text: encrypt(newMessage.value),
        roomId: roomId,
        senderId: userId.value,
        receiverId: activeChat.value.friend.FriendId,
      });

      await axios.post(API_URL + "add-message", {
        senderId: useAuth().data.value.user.id,
        receiverId: activeChat.value.friend.FriendId,
        message: encrypt(message.text),
      });

      activeChat.value.messages.push(message);
      newMessage.value = "";
      scrollToBottom();
    }
  } catch (error) {
    console.error("Error in sendMessage:", error);
  }
}

const scrollToBottom = () => {
  nextTick(() => {
    const container = messageContainer.value;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  });
};

async function searchFriends() {
  try {
    if (!newFriendQuery.value || newFriendQuery.value.length < 1) {
      searchResults.value = [];
      return;
    }
    const response = await axios.get(
      API_URL +
        `search-new-friends?query=${encodeURIComponent(newFriendQuery.value)}`
    );
    let id = useAuth().data.value.user.id;
    // where not already friends
    response.data.results = response.data.results.filter(
      (user) =>
        user.id !== id && !friends.some((friend) => friend.FriendId === user.id)
    );
    searchResults.value = response.data.results;
  } catch (error) {
    console.error("Error in searchFriends:", error);
  }
}

async function addFriend(friend) {
  try {
    const friendId = friend.id;
    const userId = useAuth().data.value.user.id;
    await axios.post(API_URL + "send-friend-request", {
      userId,
      friendId,
    });

    console.log(
      "Emitting AddFriend via socket:",
      $socket?.connected,
      userId,
      friendId
    );

    $socket.emit("AddFriend", {
      senderId: userId,
      receiverId: friendId,
    });

    alert(
      "Friend request to " +
        friend.FirstName +
        " " +
        friend.LastName +
        " sent successfully!"
    );
  } catch (error) {
    console.error("Error in addFriend:", error);
  }
}

async function getFriendRequests() {
  try {
    const userID = useAuth().data.value.user.id;
    const response = await axios.get(API_URL + `get-friend-requests/` + userID);
    return response.data.friendRequests;
  } catch (error) {
    console.error("Error in getFriendRequests:", error);
    return [];
  }
}

async function acceptRequest(request) {
  try {
    await axios.post(API_URL + "update-friend-request", {
      requestId: request.Id,
      status: 1, // Accepter la demande
    });

    // Recharger la liste des demandes d'amis
    const updatedRequests = await getFriendRequests();
    friendRequests.value = updatedRequests;

    // Recharger la liste des amis
    const updatedFriends = await getFriends();
    friends.splice(0, friends.length, ...updatedFriends); // Remplacer proprement la liste
    filteredFriends.value = friends; // Mettre à jour la liste filtrée aussi

    totalNotifications();

    $socket.emit("AcceptFriend", {
      senderId: request.SecondFriendID,
      receiverId: request.FirstFriendID,
    });
  } catch (error) {
    console.error("Error in acceptRequest:", error);
  }
}

const openAddFriendModal = () => {
  isAddFriendModalOpen.value = true;
};

const closeAddFriendModal = () => {
  isAddFriendModalOpen.value = false;
};

async function rejectRequest(request) {
  try {
    await axios.post(API_URL + "update-friend-request", {
      requestId: request.Id,
      status: 2,
    });
  } catch (error) {
    console.error("Error in rejectRequest:", error);
  }
}

async function getCustomListsInvitations() {
  try {
    const response = await axios.get(
      API_URL + `get-shared-custom-list-invites/` + userId.value
    );
    customListInvitations.value = response.data.data;

    console.log("customListInvitations:", customListInvitations.value);
  } catch (error) {
    console.error("Error in getCustomListsInvitations:", error);
    return [];
  }
}

async function getFriends() {
  try {
    const userID = useAuth().data.value.user.id;
    const response = await axios.get(API_URL + `get-friends/` + userID);
    return response.data.Friends.map((friend) => ({
      FirstName:
        friend.FirstFriendFirstName === useAuth().data.value.user.FirstName
          ? friend.SecondFriendFirstName
          : friend.FirstFriendFirstName,
      LastName:
        friend.FirstFriendLastName === useAuth().data.value.user.LastName
          ? friend.SecondFriendLastName
          : friend.FirstFriendLastName,
      FriendId:
        friend.FirstFriendID === userID
          ? friend.SecondFriendID
          : friend.FirstFriendID,
    }));
  } catch (error) {
    console.error("Error in getFriends:", error);
    return [];
  }
}

async function acceptInvitation(invitation) {
  try {
    let userId = useAuth().data.value.user.id;
    if (invitation.invitedId != userId) {
      console.error("Invalid user trying to accept invitation.");
      return;
    }

    await axios.post(API_URL + "update-shared-custom-list-invite", {
      inviteId: invitation.id,
      customListType: invitation.customListType,
      customListId: invitation.customListId,
      invitedId: invitation.invitedId,
      status: "accept",
    });

    let index = customListInvitations.value.findIndex(
      (invite) => invite.id === invitation.id
    );
    customListInvitations.value.splice(index, 1);
  } catch (error) {
    console.error("Error in acceptInvitation:", error);
  }
}

async function declineInvitation(invitation) {
  let userId = useAuth().data.value.user.id;
  if (invitation.invitedId != userId) {
    console.error("Invalid user trying to decline invitation.");
    return;
  }

  try {
    await axios.post(API_URL + "update-shared-custom-list-invite", {
      inviteId: invitation.id,
      customListType: invitation.customListType,
      customListId: invitation.customListId,
      invitedId: invitation.invitedId,
      status: "decline",
    });

    let index = customListInvitations.value.findIndex(
      (invite) => invite.id === invitation.id
    );
    customListInvitations.value.splice(index, 1);
  } catch (error) {
    console.error("Error in declineInvitation:", error);
  }
}
</script>

<style scoped>
.w-fit-content {
  width: fit-content;
}
</style>
