<template>
  <header class="bg-main text-main-text shadow-lg relative z-50 h-24">
    <div class="container mx-auto flex items-center justify-between h-full px-4 sm:px-6 relative">
      
      <!-- Logo Fixe à Gauche -->
      <div class="absolute left-4 sm:left-6 top-1/2 -translate-y-1/2 z-50">
        <NuxtLink to="/" class="block">
          <img
            src="/assets/logoMlb.png"
            alt="Logo"
            class="lg:h-20 w-auto object-contain lg:min-w-[200px] h-10 min-w-[70px]"
          />
        </NuxtLink>
      </div>

      <!-- Contenu Principal -->
      <div class="flex-1 flex items-center justify-end gap-6 lg:ml-[240px] ml-[100px]">
        
        <!-- Barre de Recherche Desktop -->
        <div class="hidden lg:flex items-center flex-1 max-w-xl space-x-2">
          <input
            type="text"
            placeholder="Looking for vlogs ?"
            class="flex-grow px-4 py-2 rounded-md text-main-text-secondary focus:outline-none transition-all focus:ring-2 focus:ring-main bg-white"
            v-model="searchQuery"
            @keydown.enter="toggleSearch"
          />
          <button
            class="bg-search-button-main px-4 py-2 rounded-md transition-all hover:bg-main-gray focus:outline-none"
          >
            <i class="fas fa-search text-main-text"></i>
          </button>
          <button
            v-if="globalSearchState"
            @click="CancelSearch"
            class="bg-search-button-maint px-4 py-2 ml-2 rounded-md hover:bg-main transition-all"
          >
            <i class="fas fa-times text-main-text"></i>
          </button>
        </div>

        <!-- Menu Droite -->
        <div class="flex items-center gap-6">
          <template v-if="isAuthenticated">
            <!-- Menu Utilisateur -->
            <div class="relative flex items-center space-x-2">
              <button
                @click="toggleDropdown"
                @blur="closeDropdown"
                class="flex items-center space-x-2 focus:outline-none"
              >
                <img
                  :src="user.avatar"
                  alt="User Avatar"
                  class="w-12 h-12 rounded-full border-2 border-white"
                />
                <span class="hidden lg:block text-sm"
                  >{{ user.FirstName }} {{ user.LastName }}</span
                >
              </button>
              <div
                v-if="dropdownOpen"
                class="absolute right-0 top-full mt-2 w-48 bg-white text-main-text-secondary z-10 rounded-lg shadow-lg"
              >
                <NuxtLink
                  to="/enduser/profile"
                  @click="closeDropdown"
                  class="block px-4 py-2 hover:bg-main-gray-light text-sm"
                  >Profile</NuxtLink
                >
                <NuxtLink
                  to="/enduser/contact-list"
                  @click="closeDropdown"
                  class="block px-4 py-2 hover:bg-main-gray-light text-sm"
                  >Your Contact Messages</NuxtLink
                >
                <button
                  @click="logout"
                  class="block w-full text-left px-4 py-2 hover:bg-main-gray-light text-sm"
                >
                  Logout
                </button>
              </div>
            </div>
          </template>

          <template v-else>
            <NuxtLink
              to="/login"
              class="hover:text-main-text-light hidden lg:block text-sm"
              >Login</NuxtLink
            >
            <NuxtLink
              to="/signup"
              class="hover:text-main-text-light hidden lg:block text-sm"
              >Sign Up</NuxtLink
            >
          </template>

          <NuxtLink
            to="/public"
            class="hover:text-main-text-light hidden lg:block text-sm"
            >Main Page</NuxtLink
          >
          <NuxtLink
            to="/contact"
            class="hover:text-main-text-light hidden lg:block text-sm"
            >Contact Us</NuxtLink
          >
          <NuxtLink
            v-if="role != 'guest'"
            @click="globalShowProposeChannel = true"
            class="hover:text-main-text-light hidden lg:block"
            title="Submit a channel"
          >
            <div
              class="border-[1px] border-white rounded-full w-8 h-8 flex items-center justify-center text-xl"
            >
              +
            </div>
          </NuxtLink>
        </div>

        <!-- Boutons Mobile -->
        <div class="flex gap-3 lg:hidden">
          <button
            @click="globalShowNavSide = true"
            class="bg-red-main text-main-text p-2 rounded-md hover:bg-button-secondary"
          >
            <i class="fas fa-bars text-lg"></i>
          </button>
          <button
            @click="toggleMobileSearch"
            class="bg-red-main text-main-text p-2 rounded-md hover:bg-button-secondary"
          >
            <i class="fas fa-search text-lg"></i>
          </button>
          <button
            @click="toggleMobileMenu"
            class="bg-red-main text-main-text p-2 rounded-md hover:bg-button-secondary"
          >
            <i class="fas fa-bars text-lg"></i>
          </button>
        </div>
      </div>

      <!-- Menu Mobile -->
      <div
        v-if="mobileMenuOpen"
        class="lg:hidden absolute top-full left-0 right-0 bg-main-dark shadow-lg z-40"
      >
        <div class="container mx-auto px-4 py-4">
          <div class="flex flex-col gap-3">
            <template v-if="!isAuthenticated">
              <NuxtLink
                to="/login"
                @click="toggleMobileMenu"
                class="block py-2 px-4 hover:bg-main-dark-light rounded-md"
              >
                Login
              </NuxtLink>
              <NuxtLink
                to="/signup"
                @click="toggleMobileMenu"
                class="block py-2 px-4 hover:bg-main-dark-light rounded-md"
              >
                Sign Up
              </NuxtLink>
            </template>
            <NuxtLink
              to="/public"
              @click="toggleMobileMenu"
              class="block py-2 px-4 hover:bg-main-dark-light rounded-md"
            >
              Main Page
            </NuxtLink>
            <NuxtLink
              to="/contact"
              @click="toggleMobileMenu"
              class="block py-2 px-4 hover:bg-main-dark-light rounded-md"
            >
              Contact Us
            </NuxtLink>
            <button
              v-if="role != 'guest'"
              @click="globalShowProposeChannel = true; toggleMobileMenu()"
              class="block w-full text-left py-2 px-4 hover:bg-main-dark-light rounded-md"
            >
              Submit a Channel
            </button>
          </div>
        </div>
      </div>

      <!-- Recherche Mobile -->
      <div
        v-if="mobileSearchOpen"
        class="lg:hidden absolute top-full left-0 right-0 bg-main-dark py-4 px-4 shadow-lg"
      >
        <div class="container mx-auto">
          <div class="flex items-center gap-2">
            <input
              type="text"
              placeholder="Search"
              class="flex-grow px-4 py-2 rounded-l-md text-main-text-secondary focus:outline-none bg-white/20"
              v-model="searchQuery"
              @keydown.enter="toggleSearch"
            />
            <button
              class="bg-red-main px-4 py-2 rounded-r-md transition-all hover:bg-main-dark-light"
            >
              <i class="fas fa-search text-main-text"></i>
            </button>
            <button
              @click="mobileSearchOpen = false"
              class="bg-red-main px-4 py-2 rounded-md hover:bg-main-dark-light"
            >
              <i class="fas fa-times text-main-text"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>


<script setup>
import { ref, watch, onMounted, inject } from "vue";
import { useRouter, useRuntimeConfig } from "#imports"; // Assuming Nuxt 3
import axios from "axios";

// Refs for reactive data
const role = ref("guest");
const searchQuery = ref("");
const isAuthenticated = ref(false);
const dropdownOpen = ref(false);
const mobileMenuOpen = ref(false);
const mobileSearchOpen = ref(false); // Added for mobile search visibility
const dropdownStyle = ref({
  top: "100%", // Default positioning (below the button)
  bottom: "auto",
});

// User data
const user = ref({
  avatar: "https://via.placeholder.com/40",
  FirstName: "Guest",
  LastName: "",
});

// Inject global states
const globalSearchState = inject("globalSearchState");
const globalSeachQuery = inject("globalSeachQuery");
const globalShowNavSide = inject("globalShowNavSide");
const globalShowProposeChannel = inject("globalShowProposeChannel");

// Router and runtime config
const router = useRouter();
const config = useRuntimeConfig();
const API_URL = config.public.apiUrl;

// Track active route
const activeRoute = ref(router.currentRoute.value.fullPath);
watch(
  () => router.currentRoute.value.fullPath,
  (newRoute) => {
    activeRoute.value = newRoute;
  }
);

// Search functionality

const toggleSearch = async () => {
  if (activeRoute.value !== "/public") {
    await router.push("/public"); // Attendre que la navigation soit terminée
    await nextTick(); // Attendre la prochaine mise à jour du DOM
  }

  if (searchQuery.value === "") {
    globalSearchState.value = false;
    globalSeachQuery.value = "";
  } else {
    globalSearchState.value = true;
    globalSeachQuery.value = searchQuery.value;
  }
};

const CancelSearch = () => {
  searchQuery.value = "";
  globalSeachQuery.value = "";
  globalSearchState.value = false;
  window.location.reload();
};

// Mobile search toggle
const toggleMobileSearch = () => {
  mobileSearchOpen.value = !mobileSearchOpen.value;
};

// Authentication logic
const authData = useAuth()?.data?.value;
if (authData) {
  if (authData.user?.FirstName) {
    user.value.FirstName = authData.user.FirstName;
    user.value.LastName = authData.user.LastName;
    user.value.avatar = authData.user.image || user.value.avatar;
    isAuthenticated.value = true;
  } else if (authData.user?.name) {
    [user.value.FirstName, user.value.LastName] = authData.user.name.split(" ");
    isAuthenticated.value = true;
  }
}

// Dropdown functionality
const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const closeDropdown = () => {
  setTimeout(() => {
    dropdownOpen.value = false;
  }, 150);
};

// Mobile menu functionality
const toggleMobileMenu = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value;
};

// Logout functionality
const logout = () => {
  useAuth().signOut();
  isAuthenticated.value = false;
  router.push("/");
};

// Fetch user image
const GetUsersImage = async () => {
  try {
    const username = useAuth()?.data?.value?.user?.username;
    if (username) {
      const response = await axios.get(`${API_URL}user/photo/${username}`);
      user.value.avatar = response.data.image;
    } else if (useAuth()?.data?.value?.user?.image) {
      user.value.avatar = useAuth().data.value.user.image;
    } else {
      console.error("No username or image found in user data");
    }
  } catch (error) {
    console.error("Error fetching user image", error);
  }
};

// Adjust dropdown position to avoid overflow
const adjustDropdownPosition = () => {
  const dropdown = document.querySelector(".dropdown-menu");
  const dropdownRect = dropdown?.getBoundingClientRect();
  const windowHeight = window.innerHeight;

  if (dropdownRect && dropdownRect.top < 0) {
    dropdownStyle.value = { top: "auto", bottom: "100%" }; // Open above the button
  } else if (dropdownRect && dropdownRect.bottom > windowHeight) {
    dropdownStyle.value = { top: "auto", bottom: "100%" }; // Open above the button
  } else {
    dropdownStyle.value = { top: "100%", bottom: "auto" }; // Default, open below
  }
};

onMounted(() => {
  GetUsersImage();
  window.addEventListener("resize", adjustDropdownPosition);
  adjustDropdownPosition(); // Initial position check

  try {
    role.value = useAuth().data.value.user.role;
  } catch (error) {
    role.value = "guest";
  }
});

watch(dropdownOpen, () => {
  if (dropdownOpen.value) {
    adjustDropdownPosition();
  }
});
</script>
