<template>
  <div class="h-full flex">
    <!-- Sidebar -->
    <div class="fixed inset-0 flex z-40">
      <!-- Overlay for Mobile -->
      <div
        v-if="globalShowNavSide"
        class="fixed inset-0 bg-black bg-opacity-60 transition-opacity duration-300"
      ></div>

      <!-- Sidebar Content -->
      <div
        :class="[
          'bg-main-gray text-main-text-secondary w-80 h-full p-6 transform transition-transform duration-300 ease-in-out shadow-2xl overflow-y-auto scrollable-sidebar',
          globalShowNavSide
            ? 'translate-x-0'
            : '-translate-x-full lg:translate-x-0',
        ]"
        :style="{ height: `calc(100vh - ${headerHeight}px)` }"
      >
        <!-- Close button for mobile -->
        <div class="lg:hidden absolute top-4 right-4">
          <button
            class="text-main-text-secondary-gray-light hover:text-main-text-secondary-gray-light transition-colors"
          >
            <i class="fas fa-times text-2xl"></i>
          </button>
        </div>

        <!-- Logo and Header -->
        <div class="flex justify-between items-center">
          <button
            v-if="!globalShowNavSide"
            @click="globalShowNavSide = !globalShowNavSide"
            class="text-main-text-secondary-gray-light text-xl p-2 rounded-lg bg-red-main hover:bg-button-secondary transition-colors"
          >
            <i class="fas fa-bars"></i>
          </button>
        </div>

        <!-- Channel Lists -->
        <div class="mb-10 bg-[#d6d6dc] rounded-xl">
          <div
            class="flex justify-center items-center bg-main rounded-t-xl p-4"
          >
            <h3 class="text-xl font-semibold text-white text-center">
              CHANNEL LISTS
            </h3>
          </div>

          <ul>
            <li
              v-for="(customlist, index) in totalCustomList.channels"
              :key="index"
              class="flex flex-col mb-4 hover:bg-channel-light p-3 rounded-md transition-all duration-200"
              @mouseover="hoveredItemId = customlist.id"
              @mouseleave="hoveredItemId = null"
            >
              <div class="flex justify-between items-center">
                <span
                  @click="onCustomListSelectedNavSide(customlist.id)"
                  class="cursor-pointer text-lg text-main-text-secondary-gray-light hover:text-main-text-secondary"
                >
                  {{ customlist.customlistName }}
                </span>
                <div
                  class="flex space-x-3"
                  v-if="hoveredItemId === customlist.id"
                >
                  <button
                    @click="editCustomList(customlist, 'channel')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    @click="moveUpCustomList(customlist, 'channel')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    ↑
                  </button>
                  <button
                    @click="moveDownCustomList(customlist, 'channel')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    ↓
                  </button>
                  <button
                    @click="
                      type = 'channel';
                      deleteCustomList(customlist.id);
                    "
                    class="text-red-400 hover:text-red-500 transition-colors"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <!-- Modal affiché uniquement si l'ID et le type correspondent -->
              <div
                v-if="
                  showRenameModal &&
                  editingCustomListId === customlist.id &&
                  editingType === 'channel'
                "
                class="mt-4 p-4 bg-[#d6d6dc] rounded-lg"
              >
                <input
                  v-model="editingCustomList.customlistName"
                  type="text"
                  placeholder="Enter new list name"
                  class="w-full px-4 py-2 rounded-md bg-main-gray-dark text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div class="flex justify-end space-x-4 mt-4">
                  <button
                    @click="showRenameModal = false"
                    class="bg-main-gray-dark text-white px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    @click="renameCustomList"
                    class="bg-red-main hover:bg-button-secondary text-white px-4 py-2 rounded-md"
                  >
                    Save
                  </button>
                </div>
              </div>
            </li>
          </ul>

                    <!-- Create Channel List Modal -->
                    <div
            v-if="showCreateModal && type === 'channel'"
            class="mt-4 p-4 bg-[#d6d6dc] rounded-lg transition-all duration-300 ease-in-out transform"
            :class="
              showCreateModal
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 -translate-y-4'
            "
          >
            <input
              v-model="newCustomList.customlistName"
              type="text"
              placeholder="Enter list name"
              class="w-full px-4 py-2 rounded-md bg-main-gray-dark text-main-text-secondary focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div class="flex justify-end space-x-4 mt-4">
              <button
                @click="showCreateModal = false"
                class="bg-main-gray-dark text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                @click="createCustomList"
                class="bg-red-main hover:bg-button-secondary text-white px-4 py-2 rounded-md"
              >
                Create
              </button>
            </div>
          </div>

          <!-- Create Channel List Button -->
          <button
            @click="toggleCreateModal('channel')"
            class="mt-4 w-full h-10 py-3 px-4 text-lg rounded-b-xl shadow-md
         hover:bg-button-secondary text-main-text bg-red-main
         flex items-center justify-center text-center
         "
>
            Create New
          </button>
        </div>

        <!-- Video Lists -->
        <div class="mb-10 mt-5 bg-[#d6d6dc] rounded-xl">
          <div
            class="flex justify-center items-center bg-main rounded-t-xl p-4"
          >
            <h3 class="text-xl font-semibold text-white text-center">
              VIDEO LISTS
            </h3>
          </div>

          <ul>
            <li
              v-for="(customlist, index) in totalCustomList.videos"
              :key="index"
              class="flex flex-col mb-4 hover:bg-channel-dark p-3 rounded-md transition-all duration-200"
              @mouseover="hoveredItemId = customlist.id"
              @mouseleave="hoveredItemId = null"
            >
              <div class="flex justify-between items-center">
                <span
                  @click="onCustomListSelectedNavSideVid(customlist.id)"
                  class="cursor-pointer text-lg text-main-text-secondary-gray-light hover:text-main-text-secondary"
                >
                  {{ customlist.customlistName }}
                </span>
                <div
                  class="flex space-x-3"
                  v-if="hoveredItemId === customlist.id"
                >
                  <button
                    @click="editCustomList(customlist, 'video')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    @click="moveUpCustomList(customlist, 'video')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    ↑
                  </button>
                  <button
                    @click="moveDownCustomList(customlist, 'video')"
                    class="text-main hover:text-red-main transition-colors"
                  >
                    ↓
                  </button>
                  <button
                    @click="
                      type = 'video';
                      deleteCustomList(customlist.id);
                    "
                    class="text-red-400 hover:text-red-500 transition-colors"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <!-- Modal affiché uniquement si l'ID et le type correspondent -->
              <div
                v-if="
                  showRenameModal &&
                  editingCustomListId === customlist.id &&
                  editingType === 'video'
                "
                class="mt-4 p-4 bg-[#d6d6dc] rounded-lg transition-all duration-300 ease-in-out"
              >
                <input
                  v-model="editingCustomList.customlistName"
                  type="text"
                  placeholder="Enter new list name"
                  class="w-full px-4 py-2 rounded-md bg-main-gray-dark text-main-text-secondary focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div class="flex justify-end space-x-4 mt-4">
                  <button
                    @click="showRenameModal = false"
                    class="bg-main-gray-dark text-white px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    @click="renameCustomList"
                    class="bg-red-main hover:bg-button-secondary text-white px-4 py-2 rounded-md"
                  >
                    Save
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <!-- Create Video List Modal -->
          <div
            v-if="showCreateModal && type === 'video'"
            class="mt-4 p-4 bg-[#d6d6dc] rounded-lg transition-all duration-300 ease-in-out transform"
            :class="
              showCreateModal
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 -translate-y-4'
            "
          >
            <input
              v-model="newCustomList.customlistName"
              type="text"
              placeholder="Enter list name"
              class="w-full px-4 py-2 rounded-md bg-main-gray-dark text-main-text focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div class="flex justify-end space-x-4 mt-4">
              <button
                @click="showCreateModal = false"
                class="bg-main-gray-dark text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                @click="createCustomList"
                class="bg-red-main hover:bg-button-secondary text-white px-4 py-2 rounded-md"
              >
                Create
              </button>
            </div>
          </div>

          <!-- Create Video List Button -->
          <button
            @click="toggleCreateModal('video')"
            class="mt-4 w-full h-10 py-3 px-4 text-lg rounded-b-xl shadow-md
         hover:bg-button-secondary text-main-text bg-red-main
         flex items-center justify-center text-center
         "
>
            Create New
          </button>
        </div>

        <!-- Admin and Moderator Links -->
        <div v-if="role !== 'user' && role != 'creator'" class="mt-10">
          <h3
            class="text-xl font-semibold mb-4 text-main-text-secondary-gray-light"
          >
            Navigation
          </h3>
          <ul>
            <li v-if="role === 'admin' || role === 'moderator'" class="mb-4">
              <NuxtLink
                to="/statistics"
                class="block bg-red-main hover:bg-button-secondary text-main-text px-6 py-3 rounded-md text-lg transition-colors duration-200"
              >
                View Statistics
              </NuxtLink>
            </li>

            <li v-if="role === 'admin'" class="mb-4">
              <NuxtLink
                to="/superadmin"
                class="block bg-red-main hover:bg-button-secondary text-main-text px-6 py-3 rounded-md text-lg transition-colors duration-200"
              >
                Admin Panel
              </NuxtLink>
            </li>

            <li v-if="role === 'admin' || role === 'moderator'" class="mb-4">
              <NuxtLink
                to="/moderator"
                class="block bg-red-main hover:bg-button-secondary text-main-text px-6 py-3 rounded-md text-lg transition-colors duration-200"
              >
                Moderator Panel
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Barre de défilement minimaliste pour la sidebar */
.scrollable-sidebar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* Firefox */
}

.scrollable-sidebar::-webkit-scrollbar {
  width: 6px;
}

.scrollable-sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  transition: background 0.3s ease;
}

.scrollable-sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}

.scrollable-sidebar::-webkit-scrollbar-track {
  background: transparent;
}
</style>

<script setup>
import { ref, inject, onBeforeMount } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

const config = useRuntimeConfig();
const API_URL = config.public.apiUrl;
const hoveredItemId = ref(null);
const showRenameModal = ref(false);
const editingCustomList = ref(null);
const editingCustomListId = ref(null);
const editingType = ref(null);

const role = ref(null);
const userid = ref(null);
const globalShowNavSide = inject("globalShowNavSide");
const showCreateModal = ref(false);
const totalCustomList = inject("totalCustomList");
const headerHeight = inject("headerHeight");
const newCustomList = ref({
  customlistName: "",
});
const isDesktop = inject("isDesktop");
const type = ref(null);

function toggleCreateModal(listType) {
  type.value = listType;
  showCreateModal.value = !showCreateModal.value;
}

function onCustomListSelectedNavSide(customlist) {
  const router = useRouter();
  if (!isDesktop.value) {
    globalShowNavSide.value = false;
  }
  window.location.href = `/custom-list/${customlist}?type=channel`;
}

function editCustomList(customlist, listType) {
  if (
    editingCustomListId.value === customlist.id &&
    editingType.value === listType
  ) {
    showRenameModal.value = false;
    editingCustomList.value = null;
    editingCustomListId.value = null;
    editingType.value = null;
  } else {
    editingCustomList.value = { ...customlist };
    editingCustomListId.value = customlist.id;
    editingType.value = listType;
    showRenameModal.value = true;
  }
}

function renameCustomList() {
  axios
    .post(API_URL + "rename-custom-list", {
      customListId: editingCustomList.value.id,
      newName: editingCustomList.value.customlistName,
      type: editingType.value,
      userId: useAuth().data.value.user.id,
    })
    .then(() => {
      fetchCustomList(); // Recharge les listes après modification
      showRenameModal.value = false;
      editingCustomListId.value = null;
      editingType.value = null;
    })
    .catch((error) => {
      console.error(error);
    });
}

function onCustomListSelectedNavSideVid(customlist) {
  const router = useRouter();
  globalShowNavSide.value = false;
  window.location.href = `/custom-list/${customlist}?type=video`;
}

async function moveUpCustomList(customlist, type) {
  await axios.post(API_URL + "move-up-custom-list", {
    customListId: customlist.id,
    type: type,
  });
  fetchCustomList();
}

async function moveDownCustomList(customlist, type) {
  await axios.post(API_URL + "move-down-custom-list", {
    customListId: customlist.id,
    type: type,
  });
  fetchCustomList();
}

function deleteCustomList(customlist) {
  Swal.fire({
    title: "Are you sure?",
    text: "This will permanently delete the custom list!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#1f2937",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await axios.post(API_URL + "delete-custom-list", {
          customListId: customlist,
          type: type.value,
        });

        fetchCustomList();

        await Swal.fire({
          title: "Deleted!",
          text: "The custom list has been removed.",
          icon: "success",
          confirmButtonColor: "#CD201F",
        });
      } catch (error) {
        console.error("Error deleting custom list:", error);
        Swal.fire("Error", "Failed to delete the custom list.", "error");
      }
    }
  });
}

function createCustomList() {
  let id = userid.value;
  const newList = {
    name: newCustomList.value.customlistName,
    userid: id,
  };
  axios
    .post(API_URL + "create-custom-list", {
      name: newList.name,
      userid: newList.userid,
      type: type.value,
    })
    .then((response) => {
      fetchCustomList();
      showCreateModal.value = false;
      newCustomList.value.customlistName = ""; // Clear input after creation
    })
    .catch((error) => {
      console.error(error);
    });
}

async function fetchCustomList() {
  try {
    const response = await axios.post(API_URL + "load-custom-list", {
      userid: userid.value,
    });
    totalCustomList.value = JSON.parse(response.data.output);
  } catch (error) {
    console.error(error);
  }
}

onBeforeMount(() => {
  try {
    role.value = useAuth().data.value.user.role;
    userid.value = useAuth().data.value.user.id;
  } catch (error) {
    // Handle errors
  }
});
</script>
