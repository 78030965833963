import { io } from "socket.io-client";

export default defineNuxtPlugin(() => {
  const socket = io("https://api.merchlinkin.bio");

  return {
    provide: {
      socket,
    },
  };
});