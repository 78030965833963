
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46id_931gHPPuKiUjMeta } from "/GitHub/MLB_NUXT/pages/articles/[...id].vue?macro=true";
import { default as indexahCKbRNF2HMeta } from "/GitHub/MLB_NUXT/pages/contact/index.vue?macro=true";
import { default as _91_46_46_46id_93ZztbLrkFhxMeta } from "/GitHub/MLB_NUXT/pages/contact/your-message/[...id].vue?macro=true";
import { default as _91_46_46_46id_93NcyLZO4GWkMeta } from "/GitHub/MLB_NUXT/pages/custom-list/[...id].vue?macro=true";
import { default as contact_45listtPCgHOQZBlMeta } from "/GitHub/MLB_NUXT/pages/enduser/contact-list.vue?macro=true";
import { default as indexNy02DlzHQqMeta } from "/GitHub/MLB_NUXT/pages/enduser/index.vue?macro=true";
import { default as profileShlwGYye0AMeta } from "/GitHub/MLB_NUXT/pages/enduser/profile.vue?macro=true";
import { default as indexrvmTBUgqpwMeta } from "/GitHub/MLB_NUXT/pages/index.vue?macro=true";
import { default as indexZZJuP7s74ZMeta } from "/GitHub/MLB_NUXT/pages/login/index.vue?macro=true";
import { default as _91_46_46_46id_93qWY8yj1YILMeta } from "/GitHub/MLB_NUXT/pages/moderator/contact-us/[...id].vue?macro=true";
import { default as indexa3wzlSiBvzMeta } from "/GitHub/MLB_NUXT/pages/moderator/contact-us/index.vue?macro=true";
import { default as dashboardoMgGwcrO2QMeta } from "/GitHub/MLB_NUXT/pages/moderator/dashboard.vue?macro=true";
import { default as indexfGMe9M4xgoMeta } from "/GitHub/MLB_NUXT/pages/moderator/index.vue?macro=true";
import { default as _91_46_46_46type_93Czb07aoWYHMeta } from "/GitHub/MLB_NUXT/pages/profile-edit/[...type].vue?macro=true";
import { default as becomeUNWUthxyTSMeta } from "/GitHub/MLB_NUXT/pages/profile-edit/creator/become.vue?macro=true";
import { default as detailsVM55QDeJQzMeta } from "/GitHub/MLB_NUXT/pages/profile-edit/creator/details.vue?macro=true";
import { default as indexTenI5uMScfMeta } from "/GitHub/MLB_NUXT/pages/public/index.vue?macro=true";
import { default as indexEPGzQO20aLMeta } from "/GitHub/MLB_NUXT/pages/signup/index.vue?macro=true";
import { default as indexI3VKmOsZDQMeta } from "/GitHub/MLB_NUXT/pages/statistics/index.vue?macro=true";
import { default as indexsHza8DwteZMeta } from "/GitHub/MLB_NUXT/pages/superadmin/index.vue?macro=true";
import { default as settingsq0Jpri8YPJMeta } from "/GitHub/MLB_NUXT/pages/superadmin/settings.vue?macro=true";
import { default as userssp2FtbG8vQMeta } from "/GitHub/MLB_NUXT/pages/superadmin/users.vue?macro=true";
export default [
  {
    name: "articles-id",
    path: "/articles/:id(.*)*",
    component: () => import("/GitHub/MLB_NUXT/pages/articles/[...id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/GitHub/MLB_NUXT/pages/contact/index.vue")
  },
  {
    name: "contact-your-message-id",
    path: "/contact/your-message/:id(.*)*",
    component: () => import("/GitHub/MLB_NUXT/pages/contact/your-message/[...id].vue")
  },
  {
    name: "custom-list-id",
    path: "/custom-list/:id(.*)*",
    component: () => import("/GitHub/MLB_NUXT/pages/custom-list/[...id].vue")
  },
  {
    name: "enduser-contact-list",
    path: "/enduser/contact-list",
    component: () => import("/GitHub/MLB_NUXT/pages/enduser/contact-list.vue")
  },
  {
    name: "enduser",
    path: "/enduser",
    component: () => import("/GitHub/MLB_NUXT/pages/enduser/index.vue")
  },
  {
    name: "enduser-profile",
    path: "/enduser/profile",
    meta: profileShlwGYye0AMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/enduser/profile.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/GitHub/MLB_NUXT/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/GitHub/MLB_NUXT/pages/login/index.vue")
  },
  {
    name: "moderator-contact-us-id",
    path: "/moderator/contact-us/:id(.*)*",
    component: () => import("/GitHub/MLB_NUXT/pages/moderator/contact-us/[...id].vue")
  },
  {
    name: "moderator-contact-us",
    path: "/moderator/contact-us",
    component: () => import("/GitHub/MLB_NUXT/pages/moderator/contact-us/index.vue")
  },
  {
    name: "moderator-dashboard",
    path: "/moderator/dashboard",
    component: () => import("/GitHub/MLB_NUXT/pages/moderator/dashboard.vue")
  },
  {
    name: "moderator",
    path: "/moderator",
    meta: indexfGMe9M4xgoMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/moderator/index.vue")
  },
  {
    name: "profile-edit-type",
    path: "/profile-edit/:type(.*)*",
    component: () => import("/GitHub/MLB_NUXT/pages/profile-edit/[...type].vue")
  },
  {
    name: "profile-edit-creator-become",
    path: "/profile-edit/creator/become",
    component: () => import("/GitHub/MLB_NUXT/pages/profile-edit/creator/become.vue")
  },
  {
    name: "profile-edit-creator-details",
    path: "/profile-edit/creator/details",
    component: () => import("/GitHub/MLB_NUXT/pages/profile-edit/creator/details.vue")
  },
  {
    name: "public",
    path: "/public",
    component: () => import("/GitHub/MLB_NUXT/pages/public/index.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/GitHub/MLB_NUXT/pages/signup/index.vue")
  },
  {
    name: "statistics",
    path: "/statistics",
    meta: indexI3VKmOsZDQMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/statistics/index.vue")
  },
  {
    name: "superadmin",
    path: "/superadmin",
    meta: indexsHza8DwteZMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/superadmin/index.vue")
  },
  {
    name: "superadmin-settings",
    path: "/superadmin/settings",
    meta: settingsq0Jpri8YPJMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/superadmin/settings.vue")
  },
  {
    name: "superadmin-users",
    path: "/superadmin/users",
    meta: userssp2FtbG8vQMeta || {},
    component: () => import("/GitHub/MLB_NUXT/pages/superadmin/users.vue")
  }
]