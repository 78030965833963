<template>
  <div class="h-screen flex flex-col relative">
    <!-- Preloader -->
    <Preloader :isVisible="isLoading" />

    <!-- Header -->
    <Header class="flex-none" />

    <div class="flex flex-1 overflow-hidden">

      <!-- main content -->
      <main 
        class="flex-1 bg-main-gray-light overflow-y-auto transition-all duration-300"
        :class="{ 'ml-64': isDesktop && globalShowNavSide }" 
      >

      <!-- Overlay : Visible SEULEMENT sur mobile/tablette -->
      <div 
        v-if="globalShowNavSide && !isDesktop" 
        class="fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity duration-300 "
        @click="globalShowNavSide = false">
      </div>

      <!-- Menu latéral : Fixe sur PC, coulissant sur mobile -->
      <NavSide
        v-if="role != 'guest'"
        class="bg-main-gray text-main-text w-64 h-full p-4 transition-transform duration-300 ease-in-out z-40 scrollable-sidebar"
        :class="{
          'fixed inset-y-0 left-0 translate-x-0': isDesktop,  /* Fixe sur PC */
          'fixed inset-0 transform transition-transform': !isDesktop, /* Coulissant sur mobile */
          'translate-x-0': globalShowNavSide,
          '-translate-x-full': !globalShowNavSide && !isDesktop
        }"
        :style="{ marginTop: contentHeight, height: `calc(100% - ${contentHeight})` }"
      />

        <div class="container mx-auto px-4 py-12">
          <NuxtPage :class="isEditPage ? '' : 'h-full'" />
        </div>
      </main>
    </div>

    <!-- Chat -->
    <Chat v-if="role != 'guest'" />

    <!-- Ajout de la popup -->
    <ProposeChannelModal />

    <div class="flex flex-row absolute left-4 bottom-0 p-4 z-50" v-if="globalShowNavSide">
      <p class="text-sm text-gray-400">Powered by YouTube ® API</p>
      <img :src="youtubeSVG" alt="youtube" class="w-4 h-4 ml-2" />
    </div>
  </div>
</template>


<script setup>
import { ref, provide } from "vue";
import { useRouter } from "vue-router";
import Header from "~/components/Header.vue";
import NavSide from "~/components/NavSide.vue";
import Preloader from "~/components/Preloader.vue";
import Chat from "./components/Chat.vue";
import axios from "axios";
import { useWindowSize } from "@vueuse/core";
import youtubeSVG from "~/assets/logos-svg/youtube.svg";
const role = ref("guest");

// get header height
const headerHeight = ref(0);

const contentHeight = computed(() => `${headerHeight.value}px`);

onMounted(() => {
  const headerElement = document.querySelector("header");

  if (headerElement) {
    const observer = new ResizeObserver(() => {
      headerHeight.value = headerElement.getBoundingClientRect().height;
    });

    observer.observe(headerElement);

    // Nettoyage lorsque le composant est démonté
    onUnmounted(() => {
      observer.disconnect();
    });
  }
});


const router = useRouter();

const isEditPage = ref(router.currentRoute.value.path.startsWith("/profile-edit"));

const config = useRuntimeConfig()
const API_URL = config.public.apiUrl;

// useAuth().signOut();
// console.log(useAuth().data.value);
// try{
// // console.log(await axios.post(API_URL+"googleRole",{id: 116564051030224164378}));
// }
// catch(e){
//   console.log(e);
// }

const totalCustomList = ref([]);

async function fetchCustomList() {
  try {
    const response = await axios.post(
      API_URL+"load-custom-list",
      {
        userid: useAuth().data.value.user.id,
      }
    );
    totalCustomList.value = JSON.parse(response.data.output);
  } catch (error) {
    //
  }
}

onBeforeMount(() => {
  try {
    role.value = useAuth().data.value.role;
    fetchCustomList();
  } catch (error) {
    globalShowNavSide.value = false;
  }
});

// Preloader
const isLoading = ref(false);

const globalSearchState = ref(false);
const globalSeachQuery = ref([]);

const { width } = useWindowSize();
const globalShowNavSide = ref(width.value >= 1024);
// Détecter la taille de l'écran
const isDesktop = computed(() => window.innerWidth >= 1024);

const globalShowProposeChannel = ref(false);
provide("globalSearchState", globalSearchState);
provide("globalSeachQuery", globalSeachQuery);
provide("globalShowNavSide", globalShowNavSide);
provide("totalCustomList", totalCustomList);
provide("globalShowProposeChannel", globalShowProposeChannel);
provide("headerHeight", headerHeight);
provide("isDesktop", isDesktop);


const updateScreenSize = () => {
  isDesktop.value = window.innerWidth >= 1024;
  if (isDesktop.value) {
    globalShowNavSide.value = true;
  }
};

onMounted(() => {
  window.addEventListener("resize", updateScreenSize);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateScreenSize);
});


router.beforeEach(() => {
  isLoading.value = true;
});

router.afterEach(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
});
</script>

<style scoped>
/* Styles globaux pour le défilement */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

main {
  overflow-y: auto;
}

/* Barre de défilement minimaliste pour le contenu principal */
.scrollable-content {
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
  width: 6px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}

/* Barre de défilement minimaliste pour la sidebar */
.scrollable-sidebar {
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* Firefox */
}

.scrollable-sidebar::-webkit-scrollbar {
  width: 6px;
}

.scrollable-sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.scrollable-sidebar::-webkit-scrollbar-track {
  background: transparent;
}
</style>