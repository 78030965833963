<template>
  <div class="h-screen bg-black flex flex-col items-center justify-center font-mono">
    <!-- Анимация текста с курсором -->
    <div class="relative text-main-text text-2xl flex mb-4">
      <span ref="text" class="inline-block"></span>
      <span ref="cursor" class="handle bg-yellow-400"></span>
    </div>

    <!-- Кнопки -->
    <div class="mt-4 flex space-x-4">
      <!-- Кнопка для повторения анимации -->
      <button
        @click="restartAnimation"
        class="bg-main-gray-dark text-main-text px-4 py-2 rounded hover:bg-main-gray-dark"
      >
        Rejouer l'animation
      </button>

      <!-- Ссылка на главную страницу -->
      <NuxtLink
        to="/"
        class="bg-amber-500 text-main-text px-4 py-2 rounded hover:bg-amber-400"
      >
        Retour à l'accueil
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

// Texte de l'erreur par défaut
const defaultMessage = "404, page not found.";
const text = ref(null);
const cursor = ref(null);
let textInterval = null; // Pour gérer l'intervalle de texte

// Récupération de l'erreur depuis les paramètres ou un fallback par défaut
const route = useRoute();
const errorMessage = computed(() => route.query.message || defaultMessage);

onMounted(() => {
  startAnimation();
});

function startAnimation() {
  // Réinitialisation du texte
  text.value.textContent = "";
  clearInterval(textInterval);

  // Lancer l'animation du texte
  typeText(errorMessage.value, text.value);

  // Lancer le clignotement du curseur
  blinkCursor(cursor.value);
}

// Fonction pour taper le texte
function typeText(content, element) {
  let i = 0;
  const speed = 100; // Vitesse de saisie en ms
  textInterval = setInterval(() => {
    if (i < content.length) {
      element.textContent += content[i];
      i++;
    } else {
      clearInterval(textInterval);
    }
  }, speed);
}

// Fonction pour faire clignoter le curseur
function blinkCursor(element) {
  if (element) {
    element.style.visibility = "visible";
    setInterval(() => {
      element.style.visibility =
        element.style.visibility === "visible" ? "hidden" : "visible";
    }, 500); // Intervalle de clignotement
  }
}

// Redémarrer l'animation
function restartAnimation() {
  startAnimation();
}
</script>

<style scoped>
/* Curseur */
.handle {
  display: inline-block;
  width: 10px;
  height: 24px;
  margin-left: 2px;
  background-color: #ffe500;
  visibility: visible;
}
</style>
