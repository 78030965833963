<template>
    <Teleport to="body">
      <div
        v-if="globalShowProposeChannel"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <div class="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg relative">
          <button
            class="absolute top-2 right-2 text-main-text-gray hover:text-main-text-gray-dark"
            @click="closeModal"
          >
            ✕
          </button>
  
          <form @submit.prevent="submitChannel">
            <div class="mb-4">
              <label class="block text-main-text-gray-dark mb-2">URL of the channel</label>
              <input
                v-model="channelUrl"
                type="url"
                required
                class="w-full px-3 py-2 border rounded"
                placeholder="https://youtube.com/..."
              />
            </div>
  
            <div class="mb-4">
              <label class="block text-main-text-gray-dark mb-2">Description (optional)</label>
              <textarea
                v-model="channelDescription"
                class="w-full px-3 py-2 border rounded"
                placeholder="Enter a description (optional)"
              ></textarea>
            </div>
  
            <button
              type="button"
              @click="submitChannel"
              class="w-full bg-red-main hover:bg-button-secondary   text-main-text py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Send proposal
            </button>
  
          <button
            class="absolute top-2 right-2 text-main-text-gray hover:text-main-text-gray-dark"
            @click="closeModal"
          >
            ✕
          </button>
          </form>
        </div>
      </div>
    </Teleport>
  </template>
  
  <script setup>
  import { inject, ref, watch } from 'vue';
  import { useRuntimeConfig } from '#app';
  import axios from 'axios';
  
  const globalShowProposeChannel = inject('globalShowProposeChannel');
  const channelUrl = ref('');
  const channelDescription = ref('');
  
  const config = useRuntimeConfig();
  const API_URL = config.public.apiUrl;
  
  const closeModal = () => {
    globalShowProposeChannel.value = false;
  };

  const isYoutubeUrl = (url) => {
    const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return pattern.test(url);
  };
  
  const submitChannel = async () => {
    if (!useAuth().data.value || !useAuth().data.value.user) {
      alert('You must be logged in to propose a channel.');
      closeModal();
      return;
    }

    if (!channelUrl.value) {
      alert('Please enter a channel URL.');
      return;
    }

    if (!isYoutubeUrl(channelUrl.value)) {
      alert('Please enter a valid YouTube channel URL.');
      return;
    }
  
    try {
      const response = await axios.post(`${API_URL}propose-yt-channel`, {
        channelURL: channelUrl.value,
        userId: useAuth().data.value.user.id,
        description: channelDescription.value,
      });

      if(JSON.parse(response.data.output).success){
        alert('Channel submited !');
      }
      else{
        alert('This channel is already submited !');
      }
  
      channelUrl.value = '';
      channelDescription.value = '';
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };
  
  // Vérification si utilisateur connecté avant d'ouvrir la modal
  watch(globalShowProposeChannel, (val) => {
    if (val && !useAuth().data.value?.user) {
      alert('You must be logged in to propose a channel.');
      globalShowProposeChannel.value = false;
    }
  });
  </script>
  